.main-heading {
    color: red;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.51px;
}

.details-box {
    padding: 20px 10px;
}

.appointment-details-header {
    font-weight: 700;
    font-size: 25px;
    line-height: 34.05px;
    margin-bottom: 0px!important;
}

.appointment-details-subheader {
    font-size: 16px;
    line-height: 21.79px;
}

.appointment-icon {
    height: 88px;
    width: 88px;
}

.appointment-icon svg {
    height: 60px;
    width: 60px;
}

.details-container {
    padding-left: 10px!important;
    padding-bottom: 0px!important;
}

.details-heading {
    margin-bottom: 0px!important;
    font-weight: 700;
    line-height: 21.79px;
    margin-top: 10px!important;
}

.patient-info {
    font-weight: normal!important;
    padding: 5px 0;
    flex: 1;
}

.button-box {
    text-transform: capitalize;
}

.heading-title {
    margin-top: 0px!important;
    font-weight: 700;
}

.visited-appointment-page .icon> svg {
    width: 66px;
    height: 65px;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
}

@media only screen and (min-width:720px) {
    .appointment-icon {
        height: 112px;
        width: 119px;
    }

    .visited-appointment-page .icon> svg {
        width: 88px;
        height: 88px;
    }

    .details-container {
        padding-top: 10px!important;
    }

    .details-heading {
        margin-top: 0px!important;
    }

    .visited-appointment-page .inner-box {
        margin-left: 21%;
    }

    .footer-details {
        padding: 10px 10px 10px 0;
    }

    .button-box {
        padding: 0px 23px;
    }
}

@media only screen and (min-width:720px) and (max-width:1024px) {
    .visited-appointment-page .inner-box {
        margin-left: 10%;
    }
}
