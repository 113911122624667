.Typogrphy-p {
    margin: auto;
    width: 40%;
}

.Typogrphy-pb {
    margin: auto;
    font-size: 20px;
    text-align: center;
}

.form-control input {
    width: 100%;
    padding: 14px 14px;
    border-radius: 8px;
}

.dob {
    display: flex;
    gap: 17px;
    padding: 0;
    margin-top: 10px;
}

.form-control-date  input {
        width: 100%;
        padding: 10px;
        font-size: inherit;
        border-radius: 8px;
}

.form-control input::placeholder {
    font-size: 16px;
}

.form-control {
    padding: 10px 0;

}

.form-control-date {
    padding: 18px 0;
}

.form-control label {
    font-weight: bold;
    font-size: 18px;
}

.form-control-date label {
    font-weight: bold;
    font-size: 18px;
}

.text-continue {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20PX;
    padding: 20px 20px;
}

.text-continue button {
    width: 20%;
    border-radius: 20px;
}

.header-partical {
    border: 1px solid #d6d6d6;
    border-radius: 28px;
    padding: 25px 20px 10px;
    margin-top: -37px;
}

.form-control .error {
    color: red;
    font-weight: 600;
    position: relative;
}

.form-control-date p {
    color: red;
    font-weight: 600;
}

.labelDate {
    font-size: 18px;
    font-weight: bold;
}

.error > span {
    position: absolute;
    left: 0;
    bottom: 100%;
}
.parent-control {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 0 20px;
}

.city-details {
    width: 50%;
}

.state-details {
    width: auto;
}

.form-city {
    width: 65%;
}

.desktop-city {
    width: auto;
}

.form-state {
    width: 30%;
}

.form-zip {
    width: 25%;
}

.form-country{
    width: 35%;
}

.form-homePhone {
    width: 48.5%;
}

.form-mobilePhone {
    width: 49%;
}

.disabled-input {
    background-color: darkgray;
}

.main-heading-title {
    font-size: 1.25rem;
}

input::placeholder, input[value], .MuiSelect-select {
    font-family: "Roboto","Helvetica","Arial",sans-serif!important;
    font-size: 13px!important;
    /* color: red; */
}

@media all and (max-width: 620px) {
    .demographic-form-container {
        margin-top: 15px;
    }

    .form-city {
        width: 100%;
    }

    .city-details {
        width: auto;
    }

    .form-state {
        width: 30%;
    }

    .form-zip {
        width: 21.5%;
    }

    .form-country {
        width: 33.5%;
    }

    .form-homePhone {
        width: 45%;
    }

    .form-mobilePhone {
        width: 47%;
    }

    .Typogrphy-p {
        width: 100%;
    }

    .text-continue button {
        width: 100%;
        border-radius: 20px;
    }
}

@media only screen and (min-width:621px) and (max-width:880px) {
    .Typogrphy-p {
        width: 100%;
    }
}
@media only screen and (min-width:881px) and (max-width:1350px) {
    .Typogrphy-p {
        width: 60%;
    }
}
