
.header-partical {
    border: 2px solid #d6d6d6;
    border-radius: 28px;
    padding: 25px 20px 10px;
    margin-top: -37px;
}

.top-heading {
    font-size: 18px;
}

.Box-p {
    margin: 0px;
}

.appointmentBtn .input-cb {
    padding: 4px 5px;
}

.footer-data {
    font-size: 16px;
    line-height: 21.79px;
    text-align: center;
}

.footer-data button {
    padding: 4px 19px;
}

.heading-subText {
    display: inline ;
}

.mutiple-appointment-header {
    font-weight: 700;
    font-size: 25px;
    line-height: 34.05px;
    display: inline-flex;
}

.booking-icon div.icon {
    width: 101px!important;
    height: 101px!important;
}

.submit-button {
    text-transform: capitalize;
    padding: 0px 30px;
    min-width: max-content;
}

@media only screen and (min-width:668px) {
    .mutiple-appointment-subheader {
        display: none;
    }

    .mutiple-appointment-subheader-desktop {
        display: block;
        font: caption;
        margin-top: 15px!important;
    }

    .mutiple-appointment-sub {
        margin-top: 15px!important;
        margin-left: 15px!important;
    }

    .booking-icon svg {
        height: 85px!important;
        width: 85px!important;
    }

    .existing-appointment {
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 24.51px;
    }
}

@media only screen and (max-width:667px) {
    .header-partical {
        display:block;
        text-align: center; 
        padding: 34px 0px 14px;
    }

    .top-heading {
        font-size: 18px;
        font-weight: 400;
        line-height: 24.51px;
    }

    .header-top {
        font-size: 16px;
    }

    .heading-subText {
        display: block;
    }

    .mutiple-appointment-header {
        font-weight: 700;
        font-size: 22px;
        line-height: 29.96px;
        display: inline-flex;
    }

    .mutiple-appointment-subheader {
        text-align: center;
        margin-top: 10px!important;
    }

    .mutiple-appointment-subheader-desktop {
        display: none;
    }

    .existing-appointment {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 21.79px;
    }

    .location-text, .patient-text {
        margin-top: 15px!important;
    }

    .appointment-changes {
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        line-height: 21.79px;
        padding: 0px 2px;
    }

    .MuiBox-root .css-i9gxme {
        border-bottom: 1px solid gray;
        padding: 10px 2px;
    }

    .appointmentBtn .input-cb {
        width: 37%;
    }

    .header-text {
        position: relative;
    }

    .header-text h4 {
        position: absolute;
        left: -50px;
    }

    .header-top-m {
        padding: 29px 0px 25px;
    }
    
    .mutiple-appointment-sub {
        margin-left: 5%;
    }

    .booking-icon div.icon {
        width: 68px!important;
        height: 68px!important;
    }

    .booking-icon svg {
        width: 50px!important;
    }
}