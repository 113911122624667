.parent-questions{
    text-align: 'center';
    margin: auto;
    width: 50%;
    padding-bottom: 20px;
}
.parent-question{
    display: flex;
}
.input-question{
    border-radius: 10px;
    padding: 14px 20px;
}
.text-continue{
    width: 100%;
    border-radius: 120px;
    justify-content: center;
}


@media only screen and (max-width: 768px) {
    .parent-questions{
        text-align: 'center';
        margin: auto;
        width: 100%;
    }
    .parent-question{
    display: flex;
  
    }
    .multi-question{
        text-align: left;
    }
    .num-question{

        padding-right: 2px;
      
    }
    .input-question{
        border-radius: 10px;
        padding: 14px 20px;
    }
  }
