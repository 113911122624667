.activity-box {
    max-width: 603px;
    padding: 5px 20px;
    width: 100%;
}

.activity-box > div > button {
    width: 117px;
}

@media screen and (max-width: 600px) {
    .activity-box > div > button {
        width: 31%;
    }
    .activity-box > div > button:nth-child(3n) {
        margin-right: 0;
    }
}