.bg-Black{
    background-color: #000;
    color: #fff;
    text-align: center;
    margin-top: 30px;
}

.bg-white {
    padding: 4px 24px;
    background-color: #fff;
    color: #2065d1;
}

.confirmation-appointment-page .footer-data button {
    padding: 4px 24px;
}

.bg-white:hover {
    padding: 7px 24px;
    background-color: #fff;
    color: #2065d1;
}

.appointment-icon svg path {
    fill: #fff;
}

.main-heading {
    color: red;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.51px;
}

.details-box {
    padding: 20px 10px;

}

.appointment-details-header {
    font-weight: 700;
    font-size: 25px;
    line-height: 34.05px;
    margin-bottom: unset!important;
    margin-left: 5% !important;
    margin-top: 20px!important;
}

.appointment-details-header-text {
    justify-content: space-evenly  !important;
    column-gap: 100px!important;
}

.appointment-details-subheader {
    font-size: 16px;
    line-height: 21.79px;
}

.confirmation-appointment-page .appointment-icon {
    height: 70px;
    width: 70px;
    margin: 0;
}

.appointment-icon-container {
    width: 24%;
}

.appointment-icon svg {
    height: 60px;
    width: 60px;
}

.details-container {
    padding-left: 10px!important;
    padding-bottom: 10px!important;
}

.details-heading {
    margin-bottom: 0px!important;
    font-weight: 700;
    line-height: 21.79px;
    margin-top: 10px!important;
}

.button-box {
    text-transform: capitalize;
}

.confirmation-appointment-page .icon> svg {
    width: 66px;
    height: 65px;
}

.confirmation-appointment-page  .header-text {
    margin-left: 26px;
}

.confirmation-appointment-page .box  {
    padding: 0px;
}

.confirmation-appointment-page {
    margin: auto;
    width: 60%;
}

.footer-details {
    padding: 17px 20px;
}

.box {
   border: 1px solid grey;
   padding: 25px;
   border-radius: 15px;
   margin-top: 24px;
   margin-bottom: 50px;
}

.inner-box {
    padding: 40px;
}

.confirmation-appointment-label .mainPage > div {
    display: flex;
    column-gap: 100px;
}
.confirmation-appointment-label .mainPage > div > .confirmation-detail-value {
    flex: 1;
}

.confirmation-appointment-label .confirmationetials {
    width: 24%;
    text-align: end;
    margin: 0;
    padding: 5px 0;
}

.confirmation-appointment-label {
    position: relative;
}

.confirmation-appointment-label .appointmentBtn {
    position: absolute;
    left: 85%;
    top: 25%;
}

.confirmation-appointment-label::after {
    content: "";
    position: absolute;
    border: 1px solid gray;
    height: 95%;
    top: 11px;
    left: 27%;
}

.confirmation-appointment-label::before {
    content: "";
    position: absolute;
    border: 1px solid gray;
    height: 70%;
    top: 11px;
    right: 20%;
}

.back-footer {
    color: #000;
    background-color: #fff;
    margin-right: 10px;
}

.confirmation-appointment-page .header-text {
    margin-left: 47px;
}

.footer-icon {
    display: flex;
    border-left: 1px solid gray;
    align-items: center;
    gap:6px;
    margin:0px 10px;
    padding: 0px 10px;
}

.footer-calender {
    display: flex;
    align-items: center;
}

@media only screen and (max-width:620px) {
    .appointment-details-header {
        margin-top: 0px!important;
    }
    
    .appointment-details-header-text {
        column-gap: 0px!important;
        margin-left: 0px!important;
    }

    .confirmation-appointment-page{
        margin: 0px;
        width: 100%;
    }

    .box{
        border: none;
        margin-top: 5px;
        margin-bottom: 5px;
     }

    .confirmation-appointment-label{
        margin: 0px;
        width: 100%;
    }

    .confirmation-appointment-label::after ,
    .confirmation-appointment-label::before {
        position: initial;
        border: none;
    }

    .confirmation-appointment-label .mainPage > div {
        display: block;
    }

    .confirmation-appointment-label .appointmentBtn {
        position: initial;
        display: flex;
        justify-content: end;
        padding-right: 30px;
    }

    .confirmation-appointment-label {
        border-bottom: 1px solid gray;
    }

    .footer-details {
        display: flow-root;
    }
}

.confirmation-appointment-page .header-text {
    padding-bottom: 10px;
}

.confirmation-appointment-page .icon> svg {
    width: 64px;
    transform: rotate(-22deg);
}

.confirmation-appointment-page .header-text p {
    margin-bottom: 15px;
}

.confirmation-appointment-page .header-top-m {
    border-bottom: none !important ;
}

.confirmation-appointment-page .header-top-m .icon {
    padding: 50px;
}

.confirmation-appointment-page .inner-box {
    margin-left: 16%;
}

.confirmation-appointment-label::after {
    left: 41%;
}

.confirmation-appointment-label::before {
    border: none;
}

 .header-text-mobile h2 {
  display: none;
}

/* .footer-content {
    display: none;
} */
.footer-content {
    display: block;
    padding: 25px;
}

.head-title {
    margin-top: 5px!important;
}
.confirmation-height {
    margin-top: 16px !important;
    padding: 0 !important;
}
.confirmation-height p {
    margin: 0;
}


@media only screen and (max-width:667px) {
    .confirmation-appointment-page .header-texts {
        padding: 0px 25px;
    }
   
    .confirmation-appointment-page .header-top-m {
        padding-top: 43%;
        position: relative;
        padding-bottom: 16px;
        margin-top: 5px;
    }
 
    .confirmation-appointment-label .mainPage .header-text {
        display: flex;
        margin-left: 14px;
         gap: 19px;
    }
    
    .box  {
        margin-bottom: 5px;
        border: 1px solid gray;
        margin: auto;
        width: 87%;
    }

    .confirmation-appointment-label {
        border-bottom:none;
    } 

    .confirmation-appointment-page .header-text-mobile h2 {
        display: block;
        font-size: 22px;
        font-weight: 700;
      }

      .confirmation-appointment-label .mainPage :nth-child(3) {
        display: none;
    }

    .footer-content {
        display: block;
        padding: 5px;
    }
      
    .confirmation-appointment-page .header-text p {
        margin-left: 5%;
    }

    .confirmation-appointment-label .confirmationetials {
        margin-bottom: 15px;
    }

    .confirmation-appointment-label .mainPage > div {
        display: block;
        margin-bottom: 10px;
        line-height: 0px;
        
    }

    .confirmation-height {
        line-height: 19px;
    }

    .confirmation-appointment-label .confirmationetials {
        width: fit-content;
        margin-bottom: 0px;
    }

    .confirmation-appointment-page .inner-box {
        margin-left: 8px;
    }

    .footer-calender {
        padding-left: 50%;
        position: relative;
        margin-left: 12px;
    }

    .footer-icon p {
        position: absolute;
        top: -14px;
        left: 0;
        font-size: 14px;
    }

    .footer-details {
        margin: auto;
    }

    .confirmation-appointment-page .footer-data button .bg-white {
        padding: 4px 25px;
        background-color: #fff;
        color: #2065d1;
    }

    .confirmation-appointment-page .footer-data button {
        padding: 4px 24px;
    }
}

@media only screen and (min-width:768px) and (max-width:900px) {
    .confirmation-appointment-page {
        width: 80%;
    }
    .confirmation-appointment-page .inner-box {
        margin-left: 4%;
    }
}

@media only screen and (min-width:901px) and (max-width:1024px) {
    .confirmation-appointment-page {
        width: 80%;
    }
    .confirmation-appointment-page .inner-box {
        margin-left: 8%;
    }
}

