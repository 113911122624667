.Typogrphy-p {
    margin: auto;
    width: 40%;
}

.form-control input {
    width: 100%;
    padding: 14px 14px;
    border-radius: 8px;
    border: 1px solid #979797;
}

.birth-date {
    display: flex;
    gap: 17px;
    padding-top: 0px;
    margin-top: 0px!important;
}

.birth-date-dob {
    display: flex;
    gap: 17px;
    padding-top: 0px;
    margin-top: -22px!important;
}

.birth-date-dob .form-control {
    width: 19%;
}

.birth-date-dob .form-control:nth-last-child(1) {
    width: 25%;
}

@media only screen and (max-width:580px) {
    .date-error-msg {
        position: relative;
        top: -10px;
    }
}

.date-control {
    display: flex;
    margin-top: -22px;
    width: 123%;
}

.date-lable{
    width: 160px;
}

.birth-date .form-control {
    width: 10%;
}

.birth-date .form-control:nth-last-child(1) {
    width: 15%;
}

.form-control-date  input {
        width: 100%;
        padding: 10px;
        font-size: inherit;
        border-radius: 8px;
}

.form-control input::placeholder {
    font-size: 16px;
}

.form-control {
    padding: 10px 0;
}

.form-control-date {
    padding: 18px 0;
}

.form-control label {
    font-weight: bold;
    font-size: 14px;
}

.form-control-date label {
    font-weight: bold;
    font-size: 14px;
}

.text-continue {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20PX;
    padding: 20px 20px;
}

.text-continue button {
    width: 20%;
    border-radius: 20px;
}

.header-partical {
    border: 1px solid #d6d6d6;
    border-radius: 28px;
    padding: 25px 20px 10px;
    margin-top: -21px;
}

.Box-p {
    padding: 16px;
    width: 100%;
    margin: auto;
}

.form-control .error {
    color: red;
    font-weight: 600;
    position: relative;
    font-size: 12px;
}

.form-control-date p {
    color: red;
    font-weight: 600;
}

span.errorMessage {
    color: red;
    font-weight: bold;
    display: inline-block!important;
    font-size: 12px;
    width: max-content;
}

.labelDate {
    font-size: 14px;
    font-weight: bold;
}

.error > span {
    position: absolute;
    left: 0;
    bottom: 100%;
}

.header-top {
    display: flex;
    justify-content:center;
    border-bottom: 1px solid gray;
    padding: 10px;
    gap: 15px;
}

.dropdowns {
    display: flex;
}

.footer-form {
    display: flex;
    justify-content:center;
}

.field-label {
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 21.79px;
}

input::placeholder, input[value] {
    font-family: "Roboto","Helvetica","Arial",sans-serif!important;
    font-size: 13px!important;
}

.insurance-type-title {
    font-size: 25px;
    margin-top: 40px;
}

.styled-input input {
    border: none;
    padding: 13px!important;
}

@media only screen and (max-width:620px) {
    .insurance-type-title {
        font-size: 20px;
        margin-top: 30px!important;
    }

    .patient-name-title {
        display: block;
    }

    .heading-box {
        padding: 10px 0;
    }

    .title-name {
        font-size: 16px!important;
    }

    .MuiTypography-root {
        width: 100%;
    }

    .MuiTypography-subtitle1 {
        text-align: center;
    }

    .birth-date .form-control {
        width: 19%;
    }
    
    .birth-date .form-control:nth-last-child(1) {
        width: 25%;
    }

    .Box-p {
        margin: 0px;
    }

    .form-div {
        margin:24px 36px
    }

    .text-continue {
        flex-direction: column-reverse;
    }

    .text-continue button {
        width: 100%;
    }

    .patient-details-wrapper {
        padding: 0px;
    }    
}

.insuranceForm {
    margin: auto;
    width: 40%;
    min-width: max-content;
}

.insuranceForm > .dropdowns {
    display: block;
}

.insuranceForm> .dropdowns > div {
    display: flex;
    justify-content: space-between;
    gap: 35px;
}

.insuranceForm .insurencedob{
    display: flex;
    gap: 10px;
}

.insuranceForm .insurencedob > span {
    width: 20%;
}

.footerdata {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid grey;
    align-items: center;
    border-bottom: 1px solid grey;
    margin-top: 10px;
}

.footerdata .footer-form {
    display: block;
    padding-top: 10px;
}

.footerdata .text-continue {
    display: block;
    width: 30%;
}

.footerdata .text-continue >button {
    width: 100%;
}

.inputAlign > div {
    width: 352px;
}

.inputAligns > div {
    width: 740px;
}

.p-width{
    width: 48%;
}

.inputdetails > div > div > div {
    padding: 0px !important;
    border-radius: 30px;
}

.inputdetails > div > div label {
    top: -7px;
}
.border-radius input{
    border-radius: 24px !important;
}
.input-borders input {
    border: 1px solid #d6d6d6;
}

.inputName {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
    border: none;
}

@media only screen and (min-width:621px) and (max-width:900px) {
    .inputdetails > div {
        max-width: 95vw;
    }
}

@media only screen and (max-width:620px) {
    .dropdowns > div {
        display: block !important;
    }

    .insuranceForm {
        margin: auto;
        width: 90%;
    }

    .footerdata {
        display: block;
    }

    .footerdata .footer-form {
        text-align: center;
    }

    .footerdata > .text-continue {
        width: 100%;
    }

    .header-top {
        display: block;
        text-align: center;
    }

    .inputAlign > div {
        width: 100%;
    }
    .inputAligns > div {
        width: 100%;
    }
    .p-width{
        width: 100%;
    }
}

/* multiple appointments page */
.multiple-appointment-page{
    margin: auto;
    width: 60%;
    min-width: max-content;
}

.header-top-m {
  width: 100%; 
  display: flex;
  justify-content: center;
  border-bottom: 1px solid gray;
  align-items: center;
  column-gap: 24px;
  padding:10px;
}

.icon {
    padding: 7px;
    border: 1px solid gray;
    background-color: #d8d8d8;
    border-radius: 100px;
}

.icon > svg {
    width: 56px;
    height: 50px;
}

.header-text {
    width: 85%;
}

.header-text h2, .header-text p {
    margin: 0px auto;
}

.input-cb {
    margin-top: 5px;
    border: 1px solid grey;
    border-radius: 20px;
    padding: 10px;
    color: hsl(202.14deg 97.91% 37.45%);
}

.border-right {
    border-right: 1px solid grey ;
}

.footer-data {
    display: flex;
    justify-content: end;
    border-top: 1px solid grey;
    align-items: center;
    border-bottom: 1px solid grey;
    margin-top: 10px;
}

.mutiple-appointment-label .mainPage > div {
    display: flex;
    column-gap: 100px;
}

.mutiple-appointment-label .appointmentDetials {
    width: 20%;
    text-align: end;
    margin: 0;
    padding: 5px 0;
}

.appointmentDetials + p {
    margin: 0;
}

.mutiple-appointment-label {
    position: relative;
    border-bottom: 1px solid grey;
}

.mutiple-appointment-label .appointmentBtn {
    position: absolute;
    left: 85%;
    top: 25%;
}

.appointmentBtn > button {
    display: flex;
    align-items: center;
}

.mutiple-appointment-label::after {
    content: "";
    position: absolute;
    border: 1px solid gray;
    height: 70%;
    top: 11px;
    left: 27%;
}

.mutiple-appointment-label::before {
    content: "";
    position: absolute;
    border: 1px solid gray;
    height: 70%;
    top: 11px;
    right: 20%;
}

.mainPage {
    padding: 20px 0;
}

.borderBotton {
    border-bottom: none !important;
}

.text-continued {
    display: flex;
    justify-content: end;
}

.text-continued p {
    width: 65%;
    text-align: end;
    padding-right: 15px;
}

@media only screen and (max-width:620px) {
    .multiple-appointment-page{
        margin: 0px;
        width: 100%;
    }

    .mutiple-appointment-label::after ,
    .mutiple-appointment-label::before {
        position: initial;
        border: none;
    }

    .mutiple-appointment-label .mainPage > div {
        display: block;
    }

    .mainPage {
        padding: 10px 30px !important;
    }

    .appointmentDetials {
        text-align: start !important;
        width: 100% !important;
    }

    .mutiple-appointment-label .appointmentBtn {
        position: initial;
        display: flex;
        justify-content: end;
        padding-right: 30px;
    }

    .mutiple-appointment-label {
        border-bottom: 1px solid gray;
        padding-bottom: 30px;
    }

    .footer-data{
        display: block;
        border-top: 1px solid grey;
        align-items: center;
        border-bottom: none;
        margin-top: 15px!important;
    }

    .text-continued {
        display: flex;
        justify-content: end;
    }

    .text-continued p {
        width: 100%;
        text-align: center;
        padding-right: 0px;
    }

    .footer-data {
        display: flex;
        flex-direction: column;
        padding: 0 30px;
    }
}
/* appointmentvisitdetails */
.visited-appointment-page{
    margin: auto;
    width: 60%;
}

.footer-details {
    padding: 0px 20px;
}

.box {
   border: 1px solid grey;
   padding: 25px;
   border-radius: 15px;
   margin-top: 50px;
   margin-bottom: 50px;
}

.inner-box {
    padding: 40px;
}

.visited-appointment-label .mainPage > div {
    display: flex;
    column-gap: 100px;
}

.visited-appointment-label .appointmentDetials {
    width: 20%;
    text-align: end;
    margin: 0;
    padding: 5px 0;
}

.visited-appointment-label {
    position: relative;
}

.visited-appointment-label .appointmentBtn {
    position: absolute;
    left: 85%;
    top: 25%;
}

.visited-appointment-label::after {
    content: "";
    position: absolute;
    border: 1px solid gray;
    height: 80%;
    top: 11px;
    left: 27%;
}

.visited-appointment-label::before {
    content: "";
    position: absolute;
    border: 1px solid gray;
    height: 70%;
    top: 11px;
    right: 20%;
}

.back-footer {
    color: #000;
    background-color: #fff;
    margin-right: 10px;
}

@media only screen and (max-width:620px) {
    .visited-appointment-page{
        margin: 0px;
        width: 100%;
    }

    .box{
        border: none;
        margin-top: 5px;
        margin-bottom: 5px;
     }

    .visited-appointment-label{
        margin: 0px;
        width: 100%;
    }

    .visited-appointment-label::after ,
    .visited-appointment-label::before {
        position: initial;
        border: none;
    }

    .visited-appointment-label .mainPage > div {
        display: block;
    }

    .visited-appointment-label .appointmentBtn {
        position: initial;
        display: flex;
        justify-content: end;
        padding-right: 30px;
    }

    .visited-appointment-label {
        border-bottom: 1px solid gray;
    }

    .footer-details {
        display: flow-root;
    }
}

.visited-appointment-page .header-text {
    border-bottom: 1px solid gray;
    line-height: 14px;
    padding-bottom: 25px;
}

.visited-appointment-page .icon> svg {
    width: 88px;
    height: 88px;
}

.visited-appointment-page .header-text p {
    margin-bottom: 15px;
}

.visited-appointment-page .header-top-m {
    border-bottom: none !important ;
}

.visited-appointment-page .header-top-m .icon {
    padding: 10px;
}

.visited-appointment-page .inner-box {
    margin-left: 16%;
}

.visited-appointment-label::after {
    left: 41%;
}

.visited-appointment-label::before {
    border: none;
}

 .header-text-mobile h2 {
  display: none;
}

/* .footer-content {
    display: none;
  } */


@media only screen and (max-width:667px) {
    .visited-appointment-page .header-text {
       position:absolute;
       line-height: 26px;
       bottom: 100px;
    }

    .visited-appointment-page .header-top-m {
        padding-top: 43%;
        position: relative;
        padding-bottom: 16px;
        margin-top: 5px;
    }

    .visited-appointment-page .header-text {
        border-bottom: none;
        width: 100%;
    }
  
    .visited-appointment-page .inner-box {
        margin-left:0px;
    }

    .visited-appointment-label {
        border-bottom:none;
    } 

    .visited-appointment-page .header-text-mobile h2 {
        display: block;
        font-size: 22px;
        font-weight: 700;
      }

      .visited-appointment-label .mainPage :nth-child(3) {
        display: none;
    }

    .footer-content {
        display: block;
        padding: 5px;
    }
      
    .visited-appointment-page .header-text p {
    margin-bottom: 11px;
    }

    .visited-appointment-page .icon> svg{
        width: 70px;
        height: 70px;
    }
}

.get-directions-link {
    display: none;
}
.map-container {
    display: block;
}

@media only screen and (max-width:1024px) {
    .get-directions-link {
        display: block;
    }
    .map-container {
        display: none;
    }
}



