@media screen and (max-width: 392px) {
    .appointment-list {
        width: 13%;
    }

    .appointment-list .appointment-box *[type="button"] {
        min-width: 100%;
        justify-content: center;
    }

    .appointment-list .appointment-box *[type="button"] > div {
        padding: 0;
    }

    .appointment-list .appointment-box *[type="button"] > div p {
        font-size: 8px;
        line-height: 12px;
    }
}

.appointment-box > button {
    justify-content: center;
}

.appointment-box > button > div {
    padding: 0px;
}